import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { notificationKeys } from '.';
import useUserAgent from '../../hooks/useUserAgent';
import { fetchNotificationEvent } from './connector';

const useNotificationEvent = () => {
  const { asPath } = useRouter();
  const { operatingSystem } = useUserAgent();

  return useQuery({
    enabled: !!operatingSystem,
    queryFn: () => fetchNotificationEvent({ operatingSystem, path: asPath }),
    queryKey: notificationKeys.notificationEvent(asPath, operatingSystem),
  });
};

export { useNotificationEvent };
