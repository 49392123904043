import { BackofficeNotification } from 'types/Notification';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

const fetchNotificationEvent = async ({ operatingSystem = 'IOS', path = '/' }) => {
  try {
    const { data } = await apiClient<BackofficeNotification>({
      params: {
        operatingSystem,
        path,
      },
      url: `/notifications/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Notification Events Connector', error, 'Error fetching notification event');
    throw error;
  }
};

export { fetchNotificationEvent };
